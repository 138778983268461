import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'
import SoftKraftLogo from '../../../static/logo/softkraft-logo.inline.svg'
import NavTogglerIcon from '../../../static/ui/nav-toggler.inline.svg'
import NavTogglerIconActive from '../../../static/ui/nav-toggler-active.inline.svg'

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import classNames from 'classnames'
import styles from './page-header.module.sass'
import PageNavigation from './PageNavigation'
import { debounce } from '../utils'

const PageHeader = ({ title, items, dark = true }) => {
  const ref = useRef(null)
  const [sticky, setSticky] = useState(false)
  const [visible, setVisible] = useState(true)
  const [navOpen, setNavOpen] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const [y, setY] = useState(0)
  const toggleNav = visible => {
    setNavOpen(visible)
  }
  const handleScroll = useCallback(
    e => {
      const window = e.currentTarget
      setSticky((window.scrollY > y && window.scrollY > 200) || (window.scrollY < y && window.scrollY > 16))
      setVisible((window.scrollY < y && visible) || (y - window.scrollY > 60 && !document.scrollingElement.onClickScroll))
      setY(window.scrollY)
      document.body.style.setProperty('--pageScroll', window.scrollY + 'px')
    },
    [y]
  )

  useEffect(() => {
    setY(document.scrollingElement.scrollTop)
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  const debouncedHandleResize = debounce(function handleResize() {
    setIsMobile(document.documentElement.offsetWidth < 992)
  }, 99)

  useEffect(() => {
    setIsMobile(document.documentElement.offsetWidth < 992)
    window.addEventListener('resize', debouncedHandleResize)
    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  }, [])

  useEffect(() => {
    navOpen ? disableBodyScroll(document.querySelector('#nav')) : enableBodyScroll(document.querySelector('#nav'))
  }, [navOpen])

  return (
    <header
      ref={ref}
      className={classNames(styles.pageHeader, dark ? 'is-dark' : 'is-light', navOpen ? 'is-opened' : 'is-collapsed', {
        'is-sticky': sticky,
        'is-visible': visible,
        'is-mobile': isMobile
      })}
    >
      <div className="container">
        <nav className={classNames(styles.navBar, 'header-nav')}>
          <Link title={title} to={'/'} className={styles.navBrand}>
            <SoftKraftLogo />
          </Link>
          <PageNavigation items={items} navOpen={navOpen} toggleNav={toggleNav} isMobile={isMobile} />
          <button className={classNames(styles.navToggler, { opened: navOpen })} onClick={() => toggleNav(!navOpen)}>
            {navOpen ? <NavTogglerIconActive /> : <NavTogglerIcon />}
            <span>Menu</span>
          </button>
        </nav>
      </div>
    </header>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      {
        layoutYaml(name: { eq: "Navigation" }) {
          name
          title
          items {
            link
            label
            items {
              link
              label
              info
              content
              items {
                link
                label
              }
            }
          }
        }
      }
    `}
    render={data => <PageHeader {...props} {...data.layoutYaml} />}
  />
)

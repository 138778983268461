import React, { useRef, useEffect, useState } from 'react'
import CustomLink from '../CustomLink'
import Link from '../Link'
import Image from '../Image'
import ArrowIcon from '../../../static/ui/arrow-icon.inline.svg'
import { url } from '../utils'
import magicLine from '../ui/magicLine.js'
import classNames from 'classnames'
import styles from './page-navigation.module.sass'
import Markdown from 'markdown-to-jsx'

const ConditionalWrapper = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children)
const handleUlClick = event => {
  event.target.nodeName === 'UL' && event.target.classList.remove('is-open')
}

export default function PageNavigation({ items, navOpen, toggleNav, isMobile }) {
  const menuRef = useRef(null)
  const [lang, setLang] = useState(null)
  useEffect(() => {
    if (navigator && navigator.language) {
      setLang(navigator.language)
    }
    Array.from(document.querySelectorAll('.' + styles.groupLink)).forEach(item => {
      item.closest('li').style.setProperty('--labelWidth', `${Math.ceil(item.getBoundingClientRect().width)}px`)
    })
  }, [])
  useEffect(() => {
    if (lang) {
      new magicLine(menuRef.current, styles.navLinkActive)
    }
  }, [lang])
  useEffect(() => {
    const handleClickOutside = event => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        let itemOpen = document.querySelector('.nav-item.is-open')
        if (itemOpen) {
          itemOpen.classList.remove('is-open')
          window.dispatchEvent(new CustomEvent('magicLineUpdate'))
        }
      }
    }
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  return (
    <ul id="nav" className={classNames(styles.navList, 'nav-list', { 'is-opened': navOpen && isMobile })} ref={menuRef}>
      {items
        .filter(item => lang === 'pl' || item.label !== 'Career')
        .map(({ link, label, items }, i) => (
          <li key={i} className={classNames(styles.navItem, 'nav-item', { 'has-dropdown': items, primary: label.match('Contact') })}>
            <CustomLink
              to={link || url(label)}
              className={classNames('nav-link', label.match('Contact') && (!navOpen || !isMobile) ? styles.navButton : styles.navLink, {
                'is-dropdown': items
              })}
              activeClassName={styles.navLinkActive}
              onClick={e => {
                toggleNav(false)
                if (items && !isMobile) {
                  e.preventDefault()
                  let itemOpen = document.querySelector('.nav-item.is-open')
                  itemOpen && itemOpen != e.target.parentNode && itemOpen.classList.remove('is-open')
                  e.target.parentNode.classList.toggle('is-open')
                }
              }}
            >
              {label}
              {label.match('Contact') && <ArrowIcon />}
              {items && (
                <svg width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.dropdownIcon}>
                  <path d="M4 4L0 0H8L4 4Z" fill="currentColor" />
                </svg>
              )}
            </CustomLink>
            {items && (
              <ConditionalWrapper
                condition={!isMobile}
                wrapper={children => <div className={classNames(styles.dropdownBlock, 'dropdown-block')}>{children}</div>}
              >
                <>
                  <button
                    className={styles.dropdownTrigger}
                    onClick={e => {
                      e.target.classList.toggle('is-open')
                      e.target.nextElementSibling.classList.toggle('is-open')
                    }}
                  ></button>

                  <ul className={classNames(styles.dropdownList, { container: !isMobile })} data-label={label} onClick={handleUlClick}>
                    {items.map(({ link, label, items, info, content }, i) =>
                      content ? (
                        <li key={i} className={styles.dropdownBox}>
                          <Markdown
                            children={content}
                            options={{
                              wrapper: function any({ children }) {
                                return children
                              },
                              forceBlock: true,
                              forceWrapper: true,
                              overrides: {
                                a: { component: Link },
                                img: { component: Image },
                                p: {
                                  component: props => {
                                    return React.Children.toArray(props.children).some(child => child.type && child.type.displayName === 'Image') ? (
                                      <figure {...props} />
                                    ) : (
                                      <p {...props} />
                                    )
                                  }
                                }
                              }
                            }}
                          />
                        </li>
                      ) : (
                        <li key={i} className={isMobile ? styles.navItem : styles.dropdownGroup}>
                          {isMobile ? (
                            <>
                              <Link
                                to={link || url(label)}
                                className={isMobile ? styles.navLink : items ? styles.groupLink : styles.dropdownLink}
                                activeClassName={'is-active'}
                                onClick={() => toggleNav(false)}
                                title={label}
                              >
                                {label}
                              </Link>
                              <button
                                className={styles.dropdownTrigger}
                                onClick={e => {
                                  e.target.classList.toggle('is-open')
                                  e.target.nextElementSibling.classList.toggle('is-open')
                                }}
                              ></button>
                            </>
                          ) : (
                            <p className={styles.groupInfo}>
                              <Link
                                to={link || url(label)}
                                className={isMobile ? styles.navLink : items ? styles.groupLink : styles.dropdownLink}
                                activeClassName={'is-active'}
                                onClick={() => toggleNav(false)}
                                title={label}
                              >
                                {label}
                              </Link>
                              {info && <span dangerouslySetInnerHTML={{ __html: info }} />}
                            </p>
                          )}
                          {items && (
                            <ul className={styles.dropdownSublist} data-label={label} onClick={handleUlClick}>
                              {items.map(({ link, label }, i) => (
                                <li key={i} className={isMobile ? styles.navItem : styles.dropdownItem}>
                                  <Link
                                    to={link || url(label)}
                                    className={isMobile ? styles.navLink : styles.dropdownLink}
                                    activeClassName={'is-active'}
                                    onClick={() => toggleNav(false)}
                                    title={label}
                                  >
                                    {label}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          )}
                        </li>
                      )
                    )}
                  </ul>
                </>
              </ConditionalWrapper>
            )}
          </li>
        ))}
    </ul>
  )
}

import React from 'react'
import { useLocation } from '@reach/router'
import { Link } from 'gatsby'
import { useStaticQuery, graphql } from 'gatsby'
import classNames from 'classnames'

export default function CustomLink(props) {
  const location = useLocation()
  const { caseStudies, posts } = useStaticQuery(graphql`
    query {
      caseStudies: allCaseStudiesYaml {
        nodes {
          path
        }
      }
      posts: allPostsYaml {
        nodes {
          path
        }
      }
    }
  `)

  const isHowWeWork =
    ['/how-staff-augmentation-works/', '/how-dedicated-development-teams-work/'].includes(location.pathname) && props.children[0] === 'How We Work'
  const isCaseStudy = caseStudies.nodes.find(node => node.path === location.pathname) && props.children[0] === 'Case Studies'
  const isBlogPost = posts.nodes.find(node => node.path === location.pathname) && props.children[0] === 'Blog'
  const isBlog = location.pathname.match('blog') && props.children[0] === 'Blog'

  const isActive = ({ isCurrent }) => {
    return isCurrent || isBlogPost || isBlog || isCaseStudy || isHowWeWork
      ? { className: classNames(props.className, props.activeClassName, 'is-active') }
      : { className: props.className }
  }

  return <Link getProps={isActive} {...props} />
}

import { debounce } from '../utils'

export default class magicLine {
  constructor(menu, activeClass) {
    if (!menu) {
      return
    }
    menu.classList.add('has-magic-line')
    this.updateActive(menu, activeClass)
    this.update = this.update.bind(this)
    menu.magicLine = this

    if (!(this.line = menu.querySelector('.magic-line'))) {
      this.line = document.createElement('li')
      this.line.className = 'magic-line'
      this.update()
      menu.appendChild(this.line)
    }

    Array.from(menu.children)
      .filter(item => !item.classList.contains('primary'))
      .forEach(item => {
        item.addEventListener('mouseenter', e => {
          return this.update(e.target)
        })
        item.addEventListener('mouseleave', e => {
          return menu.querySelector('.is-open') ? this.update(menu.querySelector('.nav-item.is-open')) : this.update()
        })
      })

    const debouncedHandleResize = debounce(
      function handleResize() {
        this.update()
      }.bind(this),
      99
    )
    window.addEventListener('DOMContentLoaded', debouncedHandleResize)
    window.addEventListener('resize', debouncedHandleResize)
    document.fonts && document.fonts.ready.then(debouncedHandleResize)

    window.addEventListener('magicLineUpdate', e => {
      e.detail ? this.update(e.detail) : this.update()
    })
  }

  updateActive(menu, activeClass) {
    var activeLink = menu.querySelector(`.${activeClass}`)
    this.active = activeLink && !activeLink.parentNode.classList.contains('primary') && activeLink.parentNode
  }

  update(el = this.active) {
    if (!el) {
      this.line.style.width = 0
      return
    }
    this.line.style.width = `${el.clientWidth || 0}px`
    this.line.style.transform = `translateX( ${el.offsetLeft || 0}px)`
    return
  }
}

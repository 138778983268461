import React from 'react'
import Link from '../components/Link'
import SoftKraftLogo from '../../static/logo/softkraft-logo.inline.svg'
import Button from '../components/Inlines/Button'

const socialmedia = [
  {
    icon: 'linkedin',
    link: 'https://www.linkedin.com/company/softkraft/',
    label: 'SoftKraft on LinkedIn'
  },
  {
    icon: 'medium',
    link: 'https://medium.com/@softkraft',
    label: 'SoftKraft on Medium'
  }
]

const Footer = () => (
  <footer id="footer" className="d-print-none overflow-hidden text-center text-sm-left">
    <div className="container">
      <div className="row pb-4">
        <div className="align-self-center col-sm-6 col-lg-4">
          <SoftKraftLogo className="footer-logo" />
        </div>
        <div className="align-self-end col-sm-12 col-lg-5 pt-4 order-sm-12 order-lg-0 text-white h2-bold">
          <span className="h3 text-white font-weight-bolder">
            Accelerate Your
            <br className="d-block d-sm-none d-lg-block" />
            Software Development
          </span>
        </div>
        <div className="align-self-center col-sm-6 col-lg-3 text-sm-center text-lg-left">
          <Button to="/contact/">Get In Touch</Button>
        </div>
      </div>
    </div>
    <div className="container pb-3">
      <div className="row">
        <div className="col-sm-6 col-lg-4  py-4">
          <span className="h4">What We Do</span>

          <Link to="/it-staff-augmentation/">IT Staff Augmentation</Link>
          <br />
          <Link to="/software-development-consulting/">Software Development Consulting</Link>
          <br />
          <Link to="/saas-application-development/">SaaS Application Development</Link>
          <br />
          <Link to="/enterprise-web-application-development/">Enterprise Web App Development</Link>
        </div>
        <div className="col-sm-6 col-lg-5 py-4">
          <span className="h4">Software Development Services</span>

          <Link to="/ux-ui-design/">UX / UI Design Services</Link>
          <br />
          <Link to="/custom-web-application-development/">Custom Web App Development</Link>
          <br />
          <Link to="/front-end-development/">Front-End Development Company</Link>
          <br />
          <Link to="/python-development-company/">Python Development Services</Link>
          <br />
        </div>
        <div className="col-sm-6 col-lg-3  py-4">
          <span className="h4">Cloud Consulting</span>

          <Link to="/data-engineering-company/">Data Engineering Company</Link>
          <br />
          <Link to="/cloud-migration-consulting/">Cloud Migration Consulting</Link>
          <br />
          <Link to="/devops-consulting/">DevOps Consulting</Link>
          <br />
          <Link to="/hire-devops-team/">Hire DevOps Team</Link>
        </div>
        <div className="col-sm-6 col-lg-4  py-4 address order-1 order-lg-0">
          <p>
            <b>SoftKraft sp. z o.o.</b>
            <br />
            ul. Powstańców Śląskich&nbsp;3
            <br />
            43-300 Bielsko-Biała, Poland
            <br />
            NIP: 5472214857
            <br />
            REGON: 384471791
            <br />
            KRS: 0000806063
          </p>
        </div>
        <div className="col-sm-6 col-lg-5  py-4">
          <span className="h4">IT Projects Outsourcing</span>

          <Link to="/outsource-software-development/">Outsourcing Software Development</Link>
          <br />
          <Link to="/custom-software-development-outsourcing/">Custom Software Outsourcing</Link>
          <br />
          <Link to="/outsourcing-saas-development/">Outsourcing SaaS Development</Link>
          <br />
          <Link to="/mvp-development-for-startups/">MVP Development Services for Startups</Link>
        </div>
        <div className="col-sm-6 col-lg-3  py-4">
          <span className="h4">Trending Insights</span>

          <Link to="/software-development-partnership/">Software Development Partner</Link>
          <br />
          <Link to="/outsourced-development-team/">Outsourced Development Team</Link>
          <br />
          <Link to="/software-development-poland/">Software Development Poland</Link>
          <br />
          <Link to="/ai-development/">Custom AI Software</Link>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="row">
        <div className="d-flex col-12 p-2 mr-3">
          <div className="social-media">
            {socialmedia.map(({ icon, link, label }) => (
              <a key={link} href={link}>
                <span className="visually-hidden">{label}</span>
                <i className={`${icon}-icon`} />
              </a>
            ))}
          </div>
          <div className="d-flex ml-3">
            <small className="align-self-center">
              © 2016-2024 Copyright
              <a href="/privacy/" target="_blank" className="pl-3">
                GDPR, Privacy Policy & Cookies
              </a>
            </small>
          </div>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
